/* covid bar */
.vc_custom_1589230176825,
.vc_custom_1589230206098 {
    border-top-width: 5px !important;
    background: #eff9ff url(/wp-content/uploads/2020/04/kits-bg.jpg?id=12713) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-top-color: #3086c0 !important;
    border-top-style: solid !important;
}

/* microsoft section */
.vc_custom_1589230189750,
.vc_custom_1589230285467 {
    padding-bottom: 1% !important;
    background: #e8e8e8 url(/wp-content/uploads/2020/04/demo-request-microsoft.jpg?id=12722) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* request a quote */
.vc_custom_1563203686507 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    background-image: url(/wp-content/uploads/2019/07/red-tn.jpg?id=10614) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* gartner */
.vc_custom_1568056317273 {
    background: #344a5c url(/wp-content/uploads/2019/07/gartner-MQ-CBT_2019-darkbg.jpg?id=10699) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* padding issue */
.vc_custom_1589230081783,
.vc_custom_1589230269266 {
    margin-top: 20px !important;
    padding-top: 3% !important;
}

.vc_custom_1562962326715,
.vc_custom_1562962326715 {
    background-image: url(https://p8yvx3ntxq81420xv36jt8sy-wpengine.netdna-ssl.com/wp-content/uploads/2018/08/cyber-security-awareness.jpg?id=2230) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}




/* sensibilisation-cybersecurite */

/* hero */
.vc_custom_1537814498273 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    background-image: url(https://tndevelop.wpengine.com/wp-content/uploads/2018/09/infosecurity.jpg?id=5506) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* obtenir un devis */
.vc_custom_1581627759678 {
    margin-bottom: 50px !important;
    background: #344a5c url(https://tndevelop.wpengine.com/wp-content/uploads/2018/03/lines-web-pattern.png?id=707) !important;
}

.vc_custom_1581627759678 .terranova-home-5-steps-subtitle {
    color: white;
}

/* list section */
.vc_custom_1537798352526 {
    padding-top: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
    background-color: #e3f9f9 !important;
    border-radius: 10px !important;
}

/* tabs */
.vc_custom_1534532917506,
.vc_custom_1593198326202,
.vc_custom_1593195411595 {
    color: white !important;
    padding-top: 40px !important;
    padding-bottom: 100px !important;
    background: #344a5c url(https://tndevelop.wpengine.com/wp-content/uploads/2018/03/lines-web-pattern.png?id=707) !important;
    background-position: 0 0 !important;
    background-repeat: repeat !important;
}

/* 
Des modules de microlearning et nanolearning à fort impact
*/
.vc_custom_1534276611785 {
    background-color: #f5f5f5 !important;
}

/* apprenez-en */
.vc_custom_1537798429258 {
    padding-top: 50px !important;
    padding-bottom: 30px !important;
    background-color: #ededed !important;
}

/* triptych */
.vc_custom_1537798436421 {
    padding-bottom: 80px !important;
    background-color: #ededed !important;
}

.vc_custom_1537798586495,
.vc_custom_1537209957744,
.vc_custom_1537798619628 {
    margin-bottom: -15px !important;
}

/* request trial */
.vc_custom_1594391229017,
.vc_custom_1571860589780 {
 border-bottom-width: 1px !important;
 padding-top: 120px !important;
 padding-bottom: 120px !important;
 background: #ffffff url(/wp-content/uploads/2020/05/trial-request-bg.jpg?id=13184) !important;
 background-position: center !important;
 background-repeat: no-repeat !important;
 background-size: cover !important;
 border-bottom-color: #cecece !important;
 border-bottom-style: solid !important;
}
 
.vc_custom_1571776066568,
.vc_custom_1546270943950,
.vc_custom_1546287645477 {
 margin-top: 0px !important;
 margin-right: 0px !important;
 margin-bottom: 0px !important;
 margin-left: 0px !important;
 padding-top: 50px !important;
 padding-right: 10px !important;
 padding-bottom: 50px !important;
 padding-left: 10px !important;
}

/*
 * Gone Phishing Tournament 2020
 * (added July 15 2020)
 */

 /* Top cards */
.vc_custom_1568228116035,
.vc_custom_1568050093247,
.vc_custom_1567708119538,
.vc_custom_1594837846398,
.vc_custom_1594837873563,
.vc_custom_1594837897717,
.vc_custom_1594839718103,
.vc_custom_1594839609064,
.vc_custom_1594841920845
{
    padding-top: 10% !important;
    padding-right: 5% !important;
    padding-bottom: 10% !important;
    padding-left: 5% !important;
    background-color: #f7f7f7 !important;

    min-height: 390px !important;
}

/* Darker boxes with top accent strip */
.vc_custom_1568051559439,
.vc_custom_1568051647763,
.vc_custom_1567708302828,
.vc_custom_1568052345358,
.vc_custom_1567708313564,
.vc_custom_1567708319710,
.vc_custom_1594838280784,
.vc_custom_1594838291844,
.vc_custom_1594838306383,
.vc_custom_1594838318753,
.vc_custom_1594838329871,
.vc_custom_1594838340600,
.vc_custom_1594840493247,
.vc_custom_1594840503621,
.vc_custom_1594841760268
 {
    border-top-width: 4px !important;
    padding-top: 5% !important;
    padding-right: 5% !important;
    padding-bottom: 5% !important;
    padding-left: 5% !important;
    background-color: #344a5c !important;
    border-top-color: #ff675b !important;
    border-top-style: solid !important;

    min-height: 170px !important;
}

/* microsoft padding */
.vc_custom_1594837676612,
.vc_custom_1594837994098,
.vc_custom_1594838389623 {
    margin-bottom: -7% !important;
    font-size: 0.7em;
    text-align: center;
}

/* Tournament - Thank You */
.vc_custom_1567801335968 {
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    border-top-width: 0px !important;
    border-right-width: 0px !important;
    border-bottom-width: 0px !important;
    border-left-width: 0px !important;
    padding-top: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 40px !important;
    padding-left: 20px !important;
    background: #344a5c url(https://p8yvx3ntxq81420xv36jt8sy-wpengine.netdna-ssl.com/wp-content/uploads/2019/03/take-bait.jpg?id=9364) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.vc_custom_1567709900975 {
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    padding-right: 10px !important;
    padding-bottom: 30px !important;
    padding-left: 10px !important;
    padding-top: 35px !important;
    background-color: #ffffff !important;
}

.vc_custom_1594844468080 .vc_custom_1546284728363 {
    margin-top: 6rem !important;
}