a:hover {
    color: #7dc6f7;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
.terranova-li-toggle {
    cursor: help;
}
.terranova-li-description {
    display: none;
    font-size: 13px;
    color: #000;
}

/*********************************/
/*  TOP MENU                     */
/*********************************/
.widget.widget_text.cdb-top-bar-widget {
    font-size: 13px !important;
    font-weight: 400;
    padding-right: 15px;
}

/*********************************/
/*  HEADER                       */
/*********************************/
.cdb-header {
    background-color: #d4d7d9;
}
.terranova-main-menu-cta {
    margin: 0 !important;
}
.terranova-main-menu-cta > div {
    padding: 20px 0 !important;
}
.terranova-main-menu-cta > div > .btn {
    padding: 10px 20px;
    border-radius: 30px;
    text-transform: uppercase;
}

/*********************************/
/*  MAIN MENU                    */
/*********************************/
.terranova-menu-5-steps > div {
    padding: 10px 0 0 10px !important;
}
.terranova-menu-5-steps > div > div {
    border-right: 1px solid #6e6f72;
    text-align: center;
}
.terranova-menu-5-steps > div > div > span {
    display: block;
    font-size: 1em;
    font-weight: 400;
    margin: 10px 0;
}
.btn-menu-5-steps {
    padding: 5px 15px !important;
    border-radius: 30px;
    font-size: 0.9em;
}
.terranova-main-menu-subtitle {
    color: #61b4e4;
    font-size: 1.1em;
    font-weight: 400;
    text-transform: uppercase;
}
.terranova-submenu-row
    > li
    > ul
    > li
    > a
    > .ubermenu-target-title.ubermenu-target-text {
    color: black !important;
    font-weight: 300;
}
.terranova-submenu-row
    > li
    > ul
    > li
    > a:hover
    > .ubermenu-target-title.ubermenu-target-text {
    color: #7dc6f7 !important;
    font-weight: 400;
}

/*********************************/
/*  LANDING PAGE MENU            */
/*********************************/
.terranova-landing-menu-item {
    margin: 0px !important;
}
.terranova-landing-menu-item > div > .cdb-icon-list-item {
    padding: 9px;
}
.terranova-landing-menu-item a {
    font-size: 14px;
}

/*********************************/
/*  LANDING PAGES                */
/*********************************/
.terranova-landing-general-text {
    font-size: 18px;
    font-weight: 300;
    color: black;
    line-height: 26px;
}
.terranova-landing-form-header {
    text-align: center;
}
.terranova-landing-form-header > div > h3 {
    font-size: 28px;
    font-weight: 700;
    color: black;
    line-height: 26px;
}
.terranova-landing-form-header > div > p {
    font-size: 18px;
    font-weight: 300;
    color: black;
    line-height: 26px;
    padding: 0 30px;
}
.terranova-landing-testimonial-text {
    font-size: 16px;
    font-weight: 900;
    color: white;
    min-height: 100px;
}
.terranova-landing-testimonial-footer {
    font-size: 14px;
    font-weight: 900;
    color: white;
}
.terranova-landing-testimonial-footer > div > .container-fluid {
    padding-left: 0px;
}
.terranova-thank-you-button > a {
    min-width: 200px;
    font-size: 16px !important;
    font-weight: 900;
}

/*********************************/
/*  OFFERS / TRIAL               */
/*********************************/
.terranova-trial-icon > a > div > .cdb-feature-content {
    background-color: #fff;
    padding: 25px 0 10px 0;
    min-height: 110px;
}
.terranova-trial-icon
    > a
    > div
    > .cdb-feature-content
    > .cdb-feature-content-title {
    margin: 0;
}
.terranova-trial-icon
    > a
    > div
    > .cdb-feature-content
    > .cdb-feature-content-title
    > h6 {
    margin-bottom: 0;
}
.terranova-trial-icon
    > a
    > div
    > .cdb-feature-content
    > .cdb-feature-content-text
    > p {
    margin-bottom: 0;
    padding-bottom: 10px;
}
.terranova-trial-icon > a > div > div > .cdb-custom-icon-image {
    padding: 30px 60px;
}
.terranova-trial-icon-dark > div > .cdb-custom-icon-image {
    background-color: #344a5c;
}
.terranova-trial-icon-blue > div > .cdb-custom-icon-image {
    background-color: #3086c0;
}

/*********************************/
/*  FOOTER                       */
/*********************************/
#terranova-subscribe-email {
    background-color: white;
    border-radius: 2em;
    padding: 8px;
    text-align: center;
    font-size: 0.9em;
    min-height: 40px;
}
#terranova-subscribe-button {
    width: 100%;
    text-transform: uppercase;
    padding: 8px;
    min-height: 40px;
    font-size: 0.9em;
}
h4.cdb-widget-title {
    font-size: 1.1em;
    font-weight: 600;
    text-transform: uppercase;
    color: white !important;
}
.cdb-footer .cdb-footer-widgets .widget ul li {
    border-bottom: none;
    line-height: 18px;
}
.cdb-footer .cdb-footer-widgets .widget ul li > a:hover {
    color: #61b4e4;
    text-decoration: underline;
}
.terranova-footer-subtitle {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: white;
    font-weight: 300;
}
.terranova-footer-small {
    font-size: 0.6em;
    font-weight: 300;
    color: white;
    text-transform: uppercase;
    line-height: 1.6;
}

/*********************************/
/*  GDPR                         */
/*********************************/
#cn-notice-text a {
    color: white;
}
#cn-accept-cookie {
    border-radius: 10px;
    padding: 4px;
    font-size: 11px;
    text-transform: uppercase;
    min-width: 45px;
}
/*********************************/
/*  BLOG                         */
/*********************************/
.cdb-blog .cdb-post-thumbnail img {
    max-height: 200px !important;
}
.cdb-blog .cdb-blog-content {
    min-height: 340px;
}
.read-more-section {
    margin: 20px 0 0 0;
}
.read-more {
    border-radius: 30px;
    background-color: #fff !important;
    border: 1px solid #000 !important;
    color: #000 !important;
    text-transform: uppercase;
    padding: 10px 20px;
    font-weight: 400 !important;
    font-size: 0.9em;
}
.read-more:hover {
    background-color: #7dc6f7 !important;
    color: #fff !important;
    border: 1px solid #7dc6f7 !important;
    text-decoration: none;
}
.post-content a {
    color: #005d91;
}
.cdb-blog .cdb-post-meta a:hover {
    color: #005d91;
}
.cdb-blog .cdb-post-meta-category:before {
    content: none !important;
}
.cdb-blog .cdb-post-meta-comments {
    display: none !important;
}

/*********************************/
/*  RECENT POSTS                 */
/*********************************/
.vc_gitem-zone-mini > .vc_gitem_row.vc_row.vc_gitem-row-position-top {
    min-height: 385px;
}
.vc_btn3-container.vc_btn3-left > .vc_gitem-link {
    border-radius: 30px;
    background-color: #61b4e4 !important;
    text-transform: uppercase;
    padding: 10px 20px;
    font-weight: 400 !important;
    font-size: 0.9em;
    color: #fff;
}
.vc_btn3-container.vc_btn3-left > .vc_gitem-link:hover {
    background-color: #0283c6 !important;
    text-decoration: none;
}

/*** announcement bar ***/
.mtsnb .mtsnb-container {
    padding: 10px 42px;
    line-height: 1.3;
}
.cdb-mobile-navigation {
    z-index: 99999;
}

/****************************/
/*  CONTACT FORM 7          */
/****************************/
div.wpcf7-response-output {
    margin: 0;
    background-color: #ccc;
    color: #000;
}
div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
    border: 4px solid #f7e700;
    border-radius: 20px;
    text-align: center;
}
div.wpcf7-mail-sent-ok {
    border: 4px solid #398f14;
    border-radius: 20px;
    text-align: center;
}

/*********************************/
/*  MOBILE                       */
/*********************************/
.mobile-nav-container {
    padding: 0;
}

/* LEVEL 1 */
.terranova-mobile-menu-item1 {
    background-color: #657b8b;
    color: #fff;
}
.terranova-mobile-menu-item1 a {
    color: #fff;
}
.terranova-mobile-menu-item1.is-active {
    background-color: #344a5c;
}
.terranova-mobile-menu-item1.is-active a {
    color: #fff !important;
}

/* LEVEL 2 */
.terranova-mobile-menu-item2 {
    background-color: #4b6b84;
    padding-left: 40px !important;
}
.terranova-mobile-menu-item2 a {
}
.terranova-mobile-menu-item2.is-active {
    background-color: #fff !important;
    color: #000 !important;
}
.terranova-mobile-menu-item2.is-active a {
    background-color: #fff;
    color: #000 !important;
}

/* LEVEL 3 */
.terranova-mobile-menu-item3 {
    background-color: #fff;
    border: none !important;
}
.terranova-mobile-menu-item3.menu-item > a {
    color: #4b6b84 !important;
}

@media (max-width: 768px) {
    .cdb-top-header {
        display: none;
    }
    .wpb_wrapper
        .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-style-cdb-minimal
        .vc_tta-panel {
        display: block;
    }
    .wpb_wrapper
        .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-style-cdb-minimal
        .vc_tta-panel-body {
        display: none;
    }
    .terranova-header-section > .cdb-grid-section.row-stretch_row {
        background-image: none !important;
    }
    .terranova-ciso-section > .cdb-grid-section.row-stretch_row {
        background-image: none !important;
        background-color: #ededed !important;
    }
    .cdb-blog .cdb-blog-content-inner {
        overflow: initial;
    }
    .cdb-blog-content-inner .cdb-post-meta {
        margin-bottom: 80px;
    }
    .cdb-blog .cdb-post-meta > span {
        display: initial;
    }
}

span.gform_description {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1em;
    display: block;
    margin: 0.75em 0;
    font-weight: 300;
    color: #fff;
    padding-bottom: 0;
    margin-bottom: 0;
}

li#field_1_2,
li#field_2_2 {
    display: none;
}

li#field_1_1 label.gfield_label,
li#field_2_1 label.gfield_label {
    font-weight: 300;
    color: #fff;
    font-size: 0.875em;
}

input#input_1_1,
input#input_2_1,
input#gform_submit_button_2,
input#gform_submit_button_1 {
    border-radius: 30px;
    width: 100%;
    text-transform: uppercase;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 2px;
}

input#gform_submit_button_1,
input#gform_submit_button_2 {
    background: #d93939;
    margin-top: 1.25em;
}

#input_1_1,
#input_2_1,
input#gform_submit_button_1,
input#gform_submit_button_2 {
    max-height: 37px !important;
    min-height: 37px !important;
    line-height: 37px !important;
    padding-top: 0 !important;
}

#input_1_1,
#input_2_1 {
    color: black;
}

.spu-content {
    min-height: 500px;
}

div[id^='spu-'] {
    min-width: 50%;
}

h3.gform_title {
    font-family: Roboto, 'MS Sans Serif', Geneva, sans-serif;
    color: #fff;
    margin-top: 0;
    font-size: 1.1em;
}

.fab.fa-envelope-o:before {
    content: '\f0e0';
    font-family: FontAwesome;
}

p {
    font-size: 113.5% !important;
}
