/*! purgecss start ignore */
/* import original theme's core CSS */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;900&display=swap');
@import 'terranova/style.css';
@import 'terranova/wp-custom-css.css';
// @import 'terranova/bootstrap.css';
@import 'terranova/nuovo.css';

/*
-------- Icons Libraries --------
*/

/* Font Awesome */
@import './../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import './../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import './../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import './../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';

/* include themify icons */
@import './icon-fonts/font-awesome-4.7.0/css/font-awesome.min.css';

/* include themify icons */
@import 'https://cdn.jsdelivr.net/themify-icons/0.1.2/css/themify-icons.css';

/* include vc-open-iconic */
@import './icon-fonts/vc-open-iconic/vc_openiconic.min.css';

/* include typicons */
@import './icon-fonts/typicons/src/font/typicons.min.css';

/* include entypo */
@import './icon-fonts/vc-entypo/vc_entypo.min.css';

/* include linecons */
@import './icon-fonts/vc-linecons/vc_linecons_icons.min.css';

/* include monosocialiconsfont */
@import './icon-fonts/monosocialiconsfont//monosocialiconsfont.min.css';

/* include material font icons */
@import './icon-fonts/vc-material/vc_material.css';

/*
-------- End of Icons Libraries --------
*/

/* import Visual Composer/Bakery Builder CSS */
@import 'js_composer.min.css';
@import 'js_composer_tta.min.css';
@import 'wpb-fix.css';
/*! purgecss end ignore */

@import 'global';
@import 'typography';
@import 'utils';

// plugins
@import 'plugins/animate';
@import 'plugins/slick';
@import 'plugins/lightbox';
@import 'plugins/ts-slider';

@import 'terranova/header';
@import 'terranova/footer';
@import 'terranova/ada';
@import 'terranova/cookie';
@import 'terranova/fixes';
