@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

$primary-color: #61b4e4 !default;

.slick-slider {
    .prev {
        left: 2px;
    }
    .next {
        right: 2px;
    }

    .prev,
    .next {
        position: absolute;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        color: white;
        background-color: $primary-color;
        width: 40px;
        height: 40px;
        z-index: 1;
        cursor: pointer;
    }
}
.slick-list {
    margin-bottom: 30px;
}

// .slick-slide {
//     img {
//         float: none;
//     }
// }

.slick-dots {
    // position: relative;
    // bottom: 0;
    bottom: -20px;

    li {
        margin: 0;

        &.slick-active {
            button {
                &:before {
                    color: $primary-color;
                }
            }
        }

        button {
            &:before {
                font-size: 11px;
            }
        }
    }
}
.testimonials-slider {
    &:hover {
        .prev,
        .next {
            opacity: 1;
        }
    }
    .prev,
    .next {
        opacity: 0;
        transition: 0.3s;
    }
}
