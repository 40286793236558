/*
 * Ensure that HubSpot iframe embeds are responsive.
 */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
a {
    text-decoration: none;
}
/*! purgecss start ignore */
.hs-form-iframe {
    width: 100% !important;
}
.position-relative {
    position: relative;
}
.stretched-link {
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: '';
        background-color: rgba(0, 0, 0, 0);
    }
}

.cdb-post-nav {

    .cdb-post-nav-link {
        &.prev {
            svg {
                transform-origin: left;
            }
        }
        &.next {
            svg {
                transform-origin: right;
            }
        }
        svg {
            position: absolute;
            top: 50%;
            width: initial;
            height: initial;
            fill: #b3b3b3;
            transform: translateY(-50%) scale(0.06);
        }
    } 
} 

.page-404 {
    .btn.btn-standard.btn-default {
        background-color: #067db3;
        color: white;

        &:hover {
            background-color: #005d91;
        }
    }

    .error-page-title {
        margin-top: 64px;

        @media screen and (max-width: 1024px) {
            margin-top: 128px;
        }
    }
}

/*! purgecss end ignore */
