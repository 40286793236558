body {
    color: #222 !important;
    .vc_btn3-color-juicy-pink,
    .vc_btn3-color-pink,
    .vc_btn3-color-danger {
        background-color: #d93939 !important;
        &:hover {
            background-color: #b42025 !important;
        }
    }
    .terranova-testimonial-item > div > div > blockquote > p > a {
        color: #005d91 !important;
    }
    .tag-link:not(.post),
    .tag-cloud-link {
        color: #354a5c !important;
        background-color: #f3f3f3 !important;
    }
    .cdb-counter.cdb-counter-dark .cdb-counter-label {
        opacity: 0.8 !important;
    }
    .vc_color-orange.vc_message_box {
        color: #655210 !important;
    }
    .cdb-post-share .post-share-title {
        color: #000 !important;
    }
    .vc_color-info.vc_message_box {
        color: #041c2d !important;
    }
}
