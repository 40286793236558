.ts-loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #666;
    width: 15px;
    height: 15px;
    animation: spin 500ms linear infinite;

    margin: 0 auto;
}

.search-loader {
    padding-top: 3rem;
    padding-bottom: 2rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.opacity-100 {
    opacity: 1;
}

.opacity-0 {
    opacity: 1;
}

.transition-opacity {
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1),
        transform 0.2s ease-in;
}

.accordion__button {
    outline: none !important;
    cursor: pointer;
}
@media only screen and (max-width: 768px) {
    .cdb-blog .cdb-post-thumbnail img {
        height: auto !important;
        max-height: initial !important;
    }
}

.ts-slider {
    .cdb-grid-container {
        width: 100% !important;
        .vc_inner.vc_row {
            max-width: 1170px;
            margin-left: auto;
            margin-right: auto;
            background-image: none !important;
            background-color: transparent !important;
        }
    }
}
