.header-mask {
    height: calc(86px + 58px);
    &.header-Landing {
        height: 86px;
    }
    @media only screen and (max-width: 1024px) {
        height: 83px !important;
    }
    @media only screen and (max-width: 768px) {
        display: none !important;
    }
}
.cdb-header {
    background-color: #eee;
    .mobile-menu-icons {
        display: flex;
        align-items: center;
        position: relative;
        display: none;
        @media only screen and (max-width: 1024px) {
            display: flex;
        }
        img {
            min-width: 20px !important;
        }
        .search-btn {
            margin-right: 20px;
            .fa {
                line-height: 1 !important;
                margin-top: 5px;
            }
            .search-form {
                top: -10px;
                @media only screen and (max-width: 768px) {
                    top: 84px;
                    right: 0;
                    position: fixed;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
    .search-btn {
        display: none;
    }
    .search-btn {
        position: relative;
        cursor: pointer;
        @media only screen and (max-width: 1024px) {
            display: block;
        }
        a {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }
        .fa {
            font-size: 20px !important;
        }
        .search-submit {
            background-color: #005d91;
            color: white;
            border: none;
            outline: none;
            font-size: 20px;
            padding: 10px 10px;
        }
        .search-form {
            position: absolute;
            right: 40px;
            width: 320px;
            top: -6px;
            display: flex;
            background: white;
            border: 1px solid #e6e6e6;
            .search-input {
                border: none;
            }
        }
    }
    &-fixed {
        position: fixed;
        top: -58px;
        left: 0;
        width: 100%;
        z-index: 9;
        &.header-Landing {
            top: 0;
        }
    }
    .cdb-list-icon-content {
        padding-left: 5px;
    }
    @media only screen and (max-width: 1024px) {
        top: 0 !important;
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 9;
    }
    .cdb-social-links {
        padding: 0 6px !important;
        li {
            a {
                color: rgba(0, 0, 0, 0.6) !important;
                padding: 14px 7px !important;
            }
        }
    }
    .wpml-ls-legacy-list-horizontal {
        border: 0px solid transparent;
        padding: 0;
        clear: both;
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            a {
                display: block;
                text-decoration: none;
                padding: 5px 10px;
                line-height: 1;
                color: #303030;
                &:hover {
                    color: #61b4e4;
                }
            }
        }
    }
    .cdb-top-header {
        background-color: #ffffff;
        .search-btn {
            display: block;
            @media only screen and (max-width: 1024px) {
                display: none;
            }
        }
        .container {
            padding-left: 45px;
            padding-right: 45px;
        }
    }
    .widget_text {
        padding-right: 8px !important;
    }
    .cdb-top-bar-widget-inner {
        .textwidget {
            a {
                display: block;
                text-decoration: none;
                padding: 14px 11px;
                line-height: 1;
                color: #292929;
                &:hover {
                    color: #61b4e4;
                }
            }
        }
    }
    @media screen and (min-width: 1155px) {
        .container {
            width: auto;
            padding-left: 55px;
            padding-right: 55px;
        }
    }
    @media screen and (min-width: 768px) {
        .container {
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
        }
        .cdb-top-header-right {
            text-align: right;
        }
    }
    .nav-items {
        > ul {
            display: flex;
            align-items: center;
            > li {
                &:last-child {
                    margin: 0px 0px 0px 30px;
                }
            }
        }
        li {
            .nav-link {
                svg {
                    display: none;
                }
                &:not(.vc_btn3) {
                    padding: 30px 15px;
                    color: #292929;
                    position: relative;
                    font-size: 16.5px;
                    margin: 0px 0px 0px 30px;
                    @media only screen and (max-width: 1230px) {
                        margin: 0px 0px 0px 15px;
                    }
                    @media only screen and (max-width: 1150px) {
                        margin: 0px 0px 0px 5px;
                    }
                    &::after {
                        display: block;
                        position: absolute;
                        width: calc(100% - 30px);
                        left: 15px;
                        height: 2px;
                        background-color: #61b4e4;
                        bottom: 25px;
                        content: '';
                        transition: 0.3s;
                        transform: scaleX(0);
                        transform-origin: left center;
                    }
                    &:hover {
                        color: #61b4e4;
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
                font-size: 1em;
                text-transform: uppercase;
                color: white;
                display: block;
                font-weight: 500;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                padding: 10px 25px;
                @media only screen and (max-width: 1200px) {
                    padding: 10px 15px;
                }
            }
        }
    }
}

@keyframes mega-menu-animation {
    from {
        transform: translate(0, 1rem);
    }
    to {
        transform: translate(0, 0rem);
    }
}

.mega-menu {
    width: 100%;
    max-width: 1050px;
    position: absolute;
    top: 86px;
    right: 50%;
    transform: translateX(50%);
    background-color: #f9f9f9;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
    padding: 30px;

    .terranova-main-menu-subtitle {
        color: #344a5c;
        font-size: 1.1em;
        font-weight: 400;
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
    }

    ul {
        display: block !important;
        li {
            width: 100%;
        }
    }

    .megamenu-link {
        display: block;
        font-weight: 400;
        display: inline-block;
        padding: 10px 0;
        line-height: 1;
        font-size: 14px;
        color: #555759;
        text-decoration: none;
        position: relative;
        &:after {
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            height: 2px;
            background-color: #61b4e4;
            bottom: 0;
            content: '';
            transition: 0.3s;
            transform: scaleX(0);
            transform-origin: left center;
        }
        &:hover {
            color: #61b4e4;
            &:after {
                transform: scale(1);
            }
        }
    }
}
.menu-icon {
    display: none;
}
.mobile-menu {
    display: none;
}
.mega-menu-left-col {
    border-right: 1px solid #ccc;
    p {
        display: block;
        font-size: 1em;
        font-weight: 400;
        margin: 0 0;
    }
    .vc_btn3 {
        margin-top: 10px;
    }
}
.mega-menu-left-col + div {
    padding-left: 40px;
}
@media only screen and (max-width: 1024px) {
    .cdb-top-header {
        display: none;
    }
    .mega-menu-left-col + div {
        padding-left: 15px;
    }
    .mega-menu-left-col {
        display: none !important;
    }
    .nav-items {
        display: none;
    }
    .menu-icon {
        display: block;
    }
    .back-cover {
        position: fixed;
        width: 100%;
        height: 100%;
        min-height: -webkit-fill-available;
        max-height: -webkit-fill-available;
        background-color: rgba(0, 0, 0, 0.795);
        z-index: 999;
        left: 0;
        top: 0;
    }
    .mobile-menu {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999999;
        background: white;
        height: 100%;
        overflow: auto;
        min-height: -webkit-fill-available;
        max-height: -webkit-fill-available;
        width: 270px;
        .cdb-icon-list-item {
            display: flex;
            align-items: center;
            padding: 10px 15px 0;
            margin-top: 10px;
            .cdb-list-icon-content {
                padding-left: 10px;
            }
            p {
                margin-bottom: 0px;
                line-height: 1;
            }
        }
        .mega-menu {
            position: initial;
            width: 100% !important;
            transform: none !important;
            .terranova-main-menu-subtitle {
                margin-bottom: 5px;
                margin-top: 5px;
            }
            ul {
                margin-bottom: 20px;
            }
        }
        .cdb-social-links {
            display: flex;
            justify-content: flex-start;
            margin: 30px auto !important;
            padding-left: 30px !important;
            li {
                a {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    display: block;
                    background-color: #eee;
                    line-height: 23px;
                    text-align: center;
                    margin: 0 5px;
                    padding: 7px 5px !important;
                    color: rgba(0, 0, 0, 0.6) !important;
                }
            }
        }
        .nav-link {
            &:not(.vc_btn3) {
                padding: 13px 15px;
                background-color: #657b8b;
                color: #fff;
                position: relative;

                &:hover {
                    color: #61b4e4;
                }
            }
            text-transform: capitalize;
            font-size: 1em;
            color: white;
            display: block;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            border-bottom: 1px solid white !important;
        }
    }
}
.transition {
    transition: 0.3s;
}
.top-100 {
    top: 150px;
}
.top-0 {
    top: 87px;
}
.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}
.cdb-header .cdb-regular-header .cdb-logo-holder .cdb-logo-link {
    padding: 20px 0 !important;
}
