body,
.cdb-auto-type,
.cdb-blog .cdb-post-meta,
.cdb-blog .read-more,
.cdb-countdown-timer-holder .cdb-countdown-timer .number,
.cdb-counter .cdb-counter-number,
.cdb-footer .cdb-subfooter,
.cdb-header .cdb-logo-holder.cdb-logo-text,
.cdb-post-nav .cdb-post-nav-link,
.cdb-post-share .post-share-title,
.cdb-sidebar-inner .widget.widget_recent_reviews ul li a,
.commentlist .comment-body p.meta,
.commentlist .comment-text p.meta,
.commentlist .comment .comment-meta,
.commentlist .comment .vcard,
.heading-font,
.product .woocommerce-tabs .tabs li,
.search-results-wrapper .search-result-block .result-content .read-more,
.tag-cloud-link,
.tag-link:not(.post),
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message,
.woocommerce legend,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto, 'MS Sans Serif', Geneva, sans-serif;
    font-weight: 500;
    font-display: swap;
}
.cdb-sidebar-inner .widget .widget_shopping_cart_content ul li a.remove,
.cross-sells .product-holder .product-title,
.primary-font {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}
body {
    font-weight: 400;
}
h1,
h2 {
    color: #344a5c;
}
