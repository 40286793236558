/*
Theme Name: Terranova
Theme URI: http://demo.codebean.co/nuovo
Author: Codebean Team
Author URI: http://themeforest.net/user/Esmet
Description: SEO & Digital Agency WordPress Theme
Version: 1.0.5
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: two-columns, sticky-post, threaded-comments, translation-ready
Template: nuovowp
*/

@media (min-width: 768px) {
    .terranova-mobile-header-cta {
        display: none;
    }
}

@media (max-width: 768px) {
    .terranova-mobile-header-cta {
        display: block;
        margin: 0px 10px;
        padding: 0;
    }
    .cdb-header {
        top: 0px;
        position: fixed !important;
        width: 100% !important;
    }
    .vc_page_container {
        padding-top: 70px;
    }
    .cdb-page-heading {
        margin-top: 100px !important;
    }
    .cdb-blog .post-single.post-small-image {
        margin-top: 100px !important;
    }
    .cdb-header .cdb-logo-holder {
        width: 100% !important;
    }
    .cdb-header .cdb-regular-header .cdb-logo-holder .cdb-logo-link {
        padding: 10px;
    }
    .cdb-logo-link {
        margin-top: 0 !important;
    }
    .mobile-nav-toggle {
        padding: 10px !important;
    }
    .btn.btn-standard.btn-default.btn-small.btn-cta-mobile {
        background-color: #d93939;
        border-radius: 20px;
        padding: 6px 12px;
        min-width: unset;
        text-transform: uppercase;
        font-size: 14px;
    }
}

.tn-blog-subscription-form input[type='submit'] {
    color: #fff;
    background-color: #61b4e4;

    margin-top: 1em;

    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;

    border-radius: 2em;
}

.tn-blog-subscription-form input[type='submit']:hover {
    background-color: #005d91;
}

.tn-blog-subscription-form-input input {
    color: #555555;
}

.cdb-mobile-navigation
    .mobile-nav-container
    nav.mobile-menu
    > ul
    li
    .submenu-indicator
    .fas,
.cdb-mobile-navigation
    .mobile-nav-container
    ul.mobile-menu
    li
    .submenu-indicator
    .fas {
    font-family: Font awesome\5 Free !important;
    font-weight: 800 !important;
}

.terranova-rmt-form .gform_heading {
    display: none;
}

.terranova-rmt-form ul.gform_fields li {
    margin-bottom: 0.5rem;
}

.terranova-rmt-form .validation_message {
    color: red;
}

.terranova-rmt-form .gform_footer input[type='submit'] {
    margin-top: 2rem;
    color: #ffffff;
    background-color: #61b4e4;
    padding: 10px 20px;
    border-radius: 30px;
    text-transform: uppercase;
    padding: 9px 18px;
    min-width: 95px;
    border-radius: 30px;
    border: none;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 30px;
    line-height: 1.7em;
    text-align: center;
    -webkit-transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.terranova-rmt-form .gform_footer input[type='submit']:hover {
    background-color: #3086c0;
}

.cdb-social-links a.email > i.fab.fa-envelope-o {
    font-family: Font awesome\5 Free !important;
    font-weight: 800;
}

.cdb-social-links a.email > i.fab.fa-envelope-o::before {
    font-family: Font awesome\5 Free !important;
    content: '\f2b6' !important;
}

.wpb_wrapper
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-style-cdb-minimal
    .vc_tta-panel-heading
    .vc_tta-panel-title
    .vc_tta-title-text:after {
    font-family: Font awesome\5 Free !important;
    font-weight: 800;
}

/* covid bar */
.vc_custom_1589230176825,
.vc_custom_1589230206098 {
    border-top-width: 5px !important;
    background: #eff9ff url(/wp-content/uploads/2020/04/kits-bg.jpg?id=12713) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-top-color: #3086c0 !important;
    border-top-style: solid !important;
}

/* microsoft section */
.vc_custom_1589230189750,
.vc_custom_1589230285467 {
    padding-bottom: 1% !important;
    background: #e8e8e8
        url(/wp-content/uploads/2020/04/demo-request-microsoft.jpg?id=12722) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* request a quote */
.vc_custom_1563203686507 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    background-image: url(/wp-content/uploads/2019/07/red-tn.jpg?id=10614) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* gartner */
.vc_custom_1568056317273 {
    background: #344a5c
        url(/wp-content/uploads/2019/07/gartner-MQ-CBT_2019-darkbg.jpg?id=10699) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/*! purgecss start ignore */
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel.vc_active, 
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel.vc_active .vc_tta-panel-body {
    display: block !important;
}

.nav-items ul, .nav-items ol {
    margin-top: 0;
    margin-bottom: 0px;
}

/*! purgecss end ignore */